<template>
  <v-col
    cols="12"
    md="12"
  >
    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Пользователи системы</div>

        <div class="text-subtitle-1 font-weight-light">Общee количеств: {{ usersList.length }}</div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="usersList"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class=""
              @click="showInfoDialog(item)"
            >
              mdi-information-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <v-dialog
      v-model="informationDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Заказы пользователя: </span>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="userOrdersTableHeaders"
            :items="userOrdersData"
            hide-default-footer
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeInfoDialog()"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import UserService from '@/app/Services/UserService'
import StatisticsService from '@/app/Services/StatisticsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'

export default {
  name: 'UsersDashboard',

  mixins: [TableDialogMixin],

  data() {
    return {
      usersList: [],
      informationDialog: false,
      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: true,
          text: 'Имя',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Email',
          value: 'email',
        },
        {
          sortable: false,
          text: 'Номер',
          value: 'phone',
        },
        {
          text: 'Заказы',
          value: 'actions',
          sortable: false,
        },
      ],
      userOrdersData: [],
      userOrdersTableHeaders: [
        {
          sortable: false,
          text: 'Период',
          value: 'period',
        },
        {
          sortable: false,
          text: 'Количество',
          value: 'count',
        },
        {
          sortable: false,
          text: 'Цена',
          value: 'price',
        },
      ],
    }
  },

  computed: {},

  async created() {
    this.loading = true
    await this.getUsers()
    this.loading = false
  },

  methods: {
    async getUsers() {
      const response = await UserService.getUsers()

      if (response.status) {
        this.usersList = response.data

        this.$store.dispatch('user/setUsersData', response.data)
      }
    },

    async showInfoDialog(item) {
      const response = await StatisticsService.getStatisticsClientOrders(item.id)
      Object.entries(response.data).forEach(([key, value]) => {
        const item = { period: key, ...value }
        this.userOrdersData.push(item)
      })
      this.informationDialog = true
    },

    closeInfoDialog() {
      this.userOrdersData = []
      this.informationDialog = false
    },
  },
}
</script>
