import api from '@/app/api'

export default {
  async getUsers() {
    const response = await api.get('/user')
    return response
  },

  async createUser(userData) {
    const response = await api.post('/user', userData)
    return response
  },

  async getUserById(id) {
    const response = await api.get(`/user/${id}`)
    return response
  },

  async editUser(id, userData) {
    const response = await api.put(`/user/${id}`, userData)
    return response
  },

  async deleteUser(id) {
    const response = await api.delete(`/user/${id}`)
    return response
  },
}
